@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartProductCard";

#{$selector} {
  display: flex;
  flex-direction: row;
  min-height: 100px;

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-right: $space-xl;
    padding: $space-md;

    & > img {
      position: relative !important;
      width: 100px !important;
      height: 100px !important;
      margin-right: $space-md;
    }
  }

  &__info {
    @include text("meta");
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 66%;
    padding: $space-md;
  }

  &__info__name {
    font-weight: bold;
    text-align: end;
  }

  &__sum {
    @include text("meta");
    padding: $space-sm 0;
  }

  &__total {
    @include text("base");
    color: $text-primary;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: $space-md;
  }

  &__counter {
    display: flex;
    flex-direction: column;

    &__icon {
      color: $icon-primary;

      &--gray {
        color: $text-lightgray;
      }
    }

    &__sum {
      @include text("base");
      padding: 0;
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
